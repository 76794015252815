import React, { Component } from "react"
import Layout from "../../components/Layout"
import Seo from "../../components/seo"
import { Container, Row, Col } from "reactstrap"
import DebatesBox from "../../components/DebatesBox"

import "../../scss/page-logged-invoices.scss"
import LoggedHeader from "../../components/LoggedHeader"

class myDebates extends Component{


  state = {
    items: [],
    visible: 2,
    error: false,
  }


  componentDidMount(){
    fetch("https://jsonplaceholder.typicode.com/posts")
      .then(res => res.json())
      .then(res => {
        this.setState({
          items: res,
        })
      })
      .catch(error => {
        console.error(error)
        this.setState({
          error: true,
        })
      })
  }

  render(){
    return (
      <>
        <Seo title="Meus Debates | Talk of the Town" />
        <LoggedHeader />
        <section className={`invoices`}>
          <Container>
            <Row>
              <Col lg="12">
                <h2 className={`title`}>Próximos debates</h2>
              </Col>
            </Row>
            <Row>
              {this.state.items
                .slice(0, this.state.visible)
                .map((item, index) => {
                  return (
                    <DebatesBox
                      key={item.id}
                      widthClass={`col-12 col-lg-4 fade-in`}
                      background={require("../../images/blm-protests.png")}
                      dateHour="20/06/20 - 16h30"
                      title={item.title}
                      description={item.body}
                      buttonUrl="/app/single/"
                      buttonText="Ver material"
                    />
                  )
                })}
            </Row>
          </Container>
        </section>
      </>
    )
  }
}

export default myDebates
