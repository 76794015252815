import React, { useEffect } from 'react'
import { navigate } from 'gatsby'
import { Router } from '@reach/router'
import Layout from '../components/Layout'
import MyDebates from '../components/app/MyDebates'
import Account from '../components/app/Account'
import useAuth from '../hooks/useAuth'
import Invoices from '../components/app/invoices';
import Single from './single'

const App = ({ location }) => {
  const { state, isAuthenticated } = useAuth()
  const redirect = location.pathname.split('/').pop()
  useEffect(() => {
    if(!isAuthenticated){
      navigate('/login', { state: { redirect } });
    }
  }, [isAuthenticated, redirect]);

  return (
    <Layout>
      <Router basepath="/app">
        <MyDebates default path="/my-debates" />
        <Account path="/my-account" />
        <Invoices path="/invoices" />
        <Single path="/single" />
      </Router>
    </Layout>
  )
}
export default App
