import React, { useRef, useEffect } from "react"
import ReactInputMask from "react-input-mask"

import { useField } from "@unform/core"

const InputMask = ({ name, ...rest }) => {
  const inputRef = useRef(null)
  const { fieldName, registerField, defaultValue, error } = useField(name)

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "value",
    })
  }, [fieldName, registerField])

  return (
    <>
      <ReactInputMask ref={inputRef} defaultValue={defaultValue} {...rest} />
      {error && <span style={{ color: "#f00" }}>{error}</span>}
    </>
  )
}

export default InputMask
