import React from 'react'
import { Form } from '@unform/web'
import {
  Container, Row, Col, FormGroup, Label,
} from 'reactstrap'
import Seo from '../seo'
import Input from '../Input'
import InputMask from '../InputMask'
import Select from '../Select'
import LoggedHeader from '../LoggedHeader'
import ShowHidePass from '../ShowHidePass/ShowHidePass'

import '../../scss/page-logged-my-account.scss'

function handleSubmit(data, { reset }) {
  console.log(data)

  reset()
}

const optionsStates = [
  { value: 'AC', label: 'Acre' },
  { value: 'AL', label: 'Alagoas' },
  { value: 'AP', label: 'Amapá' },
  { value: 'AM', label: 'Amazonas' },
  { value: 'BA', label: 'Bahia' },
  { value: 'CE', label: 'Ceará' },
  { value: 'DF', label: 'Distrito Federal' },
  { value: 'ES', label: 'Espírito Santo' },
  { value: 'GO', label: 'Goiás' },
  { value: 'MA', label: 'Maranhão' },
  { value: 'MT', label: 'Mato Grosso' },
  { value: 'MS', label: 'Mato Grosso do Sul' },
  { value: 'MG', label: 'Minas Gerais' },
  { value: 'PA', label: 'Pará' },
  { value: 'PB', label: 'Paraíba' },
  { value: 'PR', label: 'Paraná' },
  { value: 'PE', label: 'Pernambuco' },
  { value: 'PI', label: 'Piauí' },
  { value: 'RJ', label: 'Rio de Janeiro' },
  { value: 'RN', label: 'Rio Grande do Norte' },
  { value: 'RS', label: 'Rio Grande do Sul' },
  { value: 'RO', label: 'Rondônia' },
  { value: 'RR', label: 'Roraima' },
  { value: 'SC', label: 'Santa Catarina' },
  { value: 'SP', label: 'São Paulo' },
  { value: 'SE', label: 'Sergipe' },
  { value: 'TO', label: 'Tocantins' },
]

const Account = () => (
  <>
    <Seo title="Meus pagamentos | Talk of the Town" />
    <LoggedHeader />
    <section className="my-account">
      <Container>
        <Row>
          <Col lg="12">
            <h2 className="title">Minha Conta</h2>
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <Form onSubmit={handleSubmit}>
              <Row className="mb-4">
                <Col lg="6">
                  <FormGroup>
                    <Label for="registerName">Nome Completo</Label>
                    <Input
                      name="registerName"
                      className="form-control"
                      id="registerName"
                    />
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup>
                    <Label for="registerEmail">E-mail</Label>
                    <Input
                      name="registerEmail"
                      id="registerEmail"
                      type="email"
                      className="form-control"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row className="mb-4">
                <Col lg="6">
                  <FormGroup>
                    <Label for="registerPhoneWhats">Celular/WhatsApp</Label>
                    <InputMask
                      name="registerPhoneWhats"
                      className="form-control"
                      id="registerPhoneWhats"
                      mask="(99) 99999-9999"
                    />
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup>
                    <Label for="registerCpf">CPF</Label>
                    <InputMask
                      name="registerCpf"
                      className="form-control"
                      id="registerCpf"
                      mask="999.999.999-99"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="4">
                  <FormGroup>
                    <Label for="registerAddress">Endereço*</Label>
                    <Input
                      name="registerAddress"
                      id="registerAddress"
                      type="text"
                      className="form-control"
                    />
                  </FormGroup>
                </Col>

                <Col lg="4">
                  <FormGroup>
                    <Label for="registerDistrict">Bairro*</Label>
                    <Input
                      name="registerDistrict"
                      id="registerDistrict"
                      type="text"
                      className="form-control"
                    />
                  </FormGroup>
                </Col>

                <Col lg="4">
                  <FormGroup>
                    <Label for="registerNumber">Número*</Label>
                    <Input
                      name="registerNumber"
                      id="registerNumber"
                      type="text"
                      className="form-control"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row className="mb-4">
                <Col lg="4">
                  <FormGroup>
                    <Label for="registerState">UF*</Label>
                    <Select
                      name="registerState"
                      options={optionsStates}
                      defaultValue={optionsStates[0]}
                    />
                  </FormGroup>
                </Col>
                <Col lg="8">
                  <FormGroup>
                    <Label for="registerCity">Cidade*</Label>
                    <Input
                      name="registerCity"
                      id="registerCity"
                      type="text"
                      className="form-control"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row className="mb-4">
                <Col lg="4">
                  <div className="form-group">
                    <Label for="registerPassword">Senha Atual</Label>
                    <ShowHidePass
                      nameInput="registerPassword"
                      id="registerPassword"
                    />
                  </div>
                </Col>
                <Col lg="4">
                  <div className="form-group">
                    <Label for="registerNewPassword">Nova Senha</Label>
                    <ShowHidePass
                      nameInput="registerNewPassword"
                      id="registerNewPassword"
                    />
                  </div>
                </Col>
                <Col lg="4">
                  <div className="form-group">
                    <Label for="registerConfirmNewPassword">
                      Confirme a Nova Senha
                    </Label>
                    <ShowHidePass
                      nameInput="registerConfirmNewPassword"
                      id="registerConfirmNewPassword"
                    />
                  </div>
                </Col>
              </Row>
              <button type="submit" className="button orange">
                Enviar
              </button>
            </Form>
          </Col>
        </Row>
      </Container>
    </section>
  </>
)
export default Account
